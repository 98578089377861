import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function editProduct() {
  const regionId = ref(null)
  const loading = ref(false)
  const dataRegion = ref({})
  regionId.value = router.currentRoute.params.id
  const fetchSpotlightList = () => {
    loading.value = true
    store.dispatch('regionConfig/getRegionConfig', regionId.value).then(response => {
      if (response.body.user) {
        dataRegion.value = response.body
      } else {
        dataRegion.value = {
          ...response.body,
          user: {},
        }
      }
      loading.value = false
    }).catch(e =>{
      loading.value = false
    })
  }
  return {
    fetchSpotlightList,
    regionId,
    loading,
    dataRegion,
  }
}
