<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card class="product-edit-wrapper">
      <validation-observer ref="addProductValidation">
        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <!-- Color backgrounds -->
            <b-col md="11">
              <b-form-group
                label="Color background"
                label-for="product-add-region"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Color background"
                  rules="required"
                >
                  <b-form-input
                    id="product-add-title"
                    v-model="dataRegion.background_top_bar"
                    type="color"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Showing -->
            <b-col md="1">
              <b-form-group
                label=""
                label-for="product-add-title"
                class="mb-2 mt-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Showing"
                  rules="required"
                >
                  <b-form-checkbox
                    v-model="dataRegion.is_show_top_bar"
                    :checked="dataRegion.is_show_top_bar"
                    name="check-button"
                    switch
                    inline
                  >
                    Showing
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Description -->
            <b-col md="12">
              <b-form-group
                label="Description"
                label-for="product-add-title"
                class="mb-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <!-- <quill-editor
                    id="product-overview"
                    v-model="dataRegion.description_top_bar"
                    class="editor-form"
                    :options="snowOption"
                  /> -->
                  <vue-editor
                    v-model="dataRegion.description_top_bar"
                  ></vue-editor>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Preview -->
            <!-- <b-col md="12">
              <b-form-group
                label="Preview"
                label-for="product-add-preview"
                class="mb-2"
              >
                <div
                  style="
                    height: 3rem;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    text-align: center;
                  "
                  :style="`background-color: ${dataRegion.background_top_bar};`"
                >
                  <div
                    style="margin-top: 1rem"
                    v-html="dataRegion.description_top_bar"
                  />
                </div>
              </b-form-group>
            </b-col> -->
          </b-row>
        </b-form>
        <!--/ form -->
      </validation-observer>

      <div class="d-flex mt-2">
        <div class="ml-auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="submit"
            >Update</b-button
          >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="ml-2"
            :to="{ name: 'top-bar' }"
            >Cancel</b-button
          >
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BImg,
  BCard,
  BOverlay,
  BRow,
  BCol,
  BTable,
  BPagination,
  BFormInput,
  BButton,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BCardBody,
  BFormTextarea,
} from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import store from "@/store";
import editProduct from "./detailProduct";
import SelectImageModal from "@/views/modal/SelectImageModal.vue";
import { onMounted } from "@vue/composition-api";
import { checkImageExtensionFile } from "@/utils/utils";
import { useToast } from "vue-toastification/composition";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import { quillEditor } from "vue-quill-editor";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    BCard,
    BOverlay,
    BPagination,
    BTable,
    BCol,
    BRow,
    BFormInput,
    BCard,
    BImg,
    BButton,
    BFormCheckbox,
    BFormGroup,
    Swiper,
    SwiperSlide,
    SelectImageModal,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BCardBody,
    BFormTextarea,
    vSelect,
    quillEditor,
    VueEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      snowOption: {
        placeholder: "",
        theme: "snow",
      },
      componentKey: 0,
      swiperOptionsBenefits: {
        allowTouchMove: false,
        centeredSlides: true,
        slidesPerView: 2,
        spaceBetween: 40,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      required,
      initSpotlight: {
        title: "",
        offeringType: "",
        url: "",
        shortDescription: "",
        image: {},
      },
    };
  },
  computed: {
    ...mapGetters("app", ["offeringTypes"]),
  },
  mounted() {
    this.fetchSpotlightList();
  },
  setup() {
    const toast = useToast();
    const { fetchSpotlightList, regionId, loading, dataRegion } = editProduct();
    return {
      regionId,
      loading,
      fetchSpotlightList,
      toast,
      dataRegion,
    };
  },

  methods: {
    mapOferringType(data) {
      return this.offeringTypes.find((x) => x.value === data).label || "";
    },
    async submit() {
      this.$refs.addProductValidation.validate().then((success) => {
        if (success) {
          this.loading = true;
          store
            .dispatch("regionConfig/updateRegionConfig", {
              id: this.$route.params.id,
              regionConfig: this.dataRegion,
            })
            .then(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Update top bar success",
                  icon: "CheckCircleIcon",
                  variant: "success",
                },
              });
            })
            .catch((e) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: e.response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.ql-container {
  min-height: inherit;
}
.ql-editor > p > a {
 text-decoration: none !important;
 color:rgba(109,106,124,255);
}


.select-image {
  flex-direction: column;
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
  border: dash grey;
}

.add-element {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: xx-large;
  background-color: #db536a;
  color: white;
  border-radius: 1rem;
  border-color: #db536a;
  cursor: pointer;
  justify-content: center;
  padding: 2rem;
}

.back-sub-element {
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-top-right-radius: 1rem;
  border-end-end-radius: 1rem;
  background-color: #db536a;
}

.sub-element {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: xx-large;
  background-color: #db536a;
  color: white;
  border-radius: 1rem;
  border-color: #db536a;
  cursor: pointer;
  justify-content: center;
}

.select-image-benefit {
  width: 10rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: dashed 2px;
  border-radius: 1rem;
  border-color: #db536a;
  cursor: pointer;
}

.swiper-button-next {
  color: #db536a;
}

.swiper-button-prev {
  color: #db536a;
}

.related-source {
  flex-direction: column;
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
}

</style>
